<template>
  <!-- Header Start -->
  <header class="bg-white">
    <slot></slot>
    <TopNav />
    <!-- <nav
      class="navbar navbar-expand-lg navbar-light px-3 px-sm-4 px-lg-4 px-xxl-5 container-xxl"
      data-aos="fade-down"
    >
      <a class="navbar-brand" href="/">
        <img src="@/assets/logo-atomic-x-red.svg" alt="Logo Atomic X" />
        Atomic X
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse justify-content-lg-end"
        id="navbarNavDropdown"
      >
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link px-lg-4" href="/about">About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link px-lg-4" href="/services">Services</a>
          </li>
          <li class="nav-item">
            <a class="nav-link px-lg-4" href="/products">Products</a>
          </li>
          <li class="nav-item">
            <a class="nav-link px-lg-4" href="/news">In The News</a>
          </li>
          <li class="nav-item">
            <a class="nav-link px-lg-4" href="/contact">Contact</a>
          </li>
        </ul>
      </div>
    </nav> -->
  </header>
  <!-- Header End -->
</template>

<script>
export default {};
</script>

<style></style>
